/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "스탠다드아웃 88번째 로그에서는 애플 세계 개발자 회의(WWDC) 2020 이야기를 나눴습니다."), "\n", React.createElement(_components.h3, null, "WWDC 2020"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://developer.apple.com/wwdc20/"
  }, "WWDC20 - Apple Developer")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.apple.com/ios/ios-14-preview/"
  }, "iOS 14 Preview - Apple")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/avstorm/status/1275113705907388416"
  }, "Andreas Storm on Twitter: \"iOS14 - New home screen https://t.co/7MEty35EM6\" / Twitter")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://developer.apple.com/app-clips/"
  }, "App Clips - Apple Developer")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.theverge.com/2020/6/22/21299182/apple-carkey-ios-14-13-digital-key-unlock-car-iphone-wwdc-2020"
  }, "Apple announces new ‘Car Keys’ that will let you wirelessly unlock your car - The Verge")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://9to5mac.com/2020/03/19/ios-14-carplay-maps/"
  }, "iOS 14: CarPlay wallpapers, deeper Apple Store integration in Maps - 9to5Mac")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://biz.chosun.com/site/data/html_dir/2020/06/23/2020062301154.html"
  }, "\"운전면허증 들고다니지 말고 'PASS'앱으로 해결하세요\" - Chosunbiz > 테크 > ICT/미디어")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.apple.com/kr/newsroom/2020/06/ipados-14-introduces-new-features-designed-specifically-for-ipad/"
  }, "iPadOS 14, iPad를 위해 디자인된 새로운 기능 공개 - Apple (KR)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.apple.com/kr/newsroom/2020/06/apple-announces-mac-transition-to-apple-silicon/"
  }, "Apple, Mac의 Apple 실리콘으로의 전환 발표 - Apple (KR)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.theverge.com/2020/6/23/21300097/fugaku-supercomputer-worlds-fastest-top500-riken-fujitsu-arm"
  }, "ARM-based Japanese supercomputer is now the fastest in the world - The Verge")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Arm_Holdings"
  }, "Arm Holdings - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.theverge.com/2019/10/1/20887386/microsoft-surface-event-pro-7-arm-windows-laptops"
  }, "ARM-based Surface: why Microsoft has to bet big on this processor - The Verge")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=FYprGQeLy_M"
  }, "윈도우에서 안드로이드폰의 문자, 통화, 화면 제어하기 - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://9to5mac.com/2020/06/21/kuo-13-3-inch-macbook-pro-and-imac-will-be-the-first-arm-macs-to-be-released-as-soon-as-q4-after-redesigned-intel-imac-launch/"
  }, "Kuo confirms ARM at WWDC: 13.3-inch MacBook Pro and new 24-inch iMac will be the first ARM Macs, released as soon as Q4 - 9to5Mac")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.theverge.com/21304182/apple-arm-mac-rosetta-2-emulation-app-converter-explainer"
  }, "Rosetta 2 is Apple’s key to making the ARM transition less painful - The Verge")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.macrumors.com/2020/06/25/tiktok-clipboard-access-ios-14-caught/"
  }, "TikTok App to Stop Accessing User Clipboards After Being Caught in the Act by iOS 14 - MacRumors")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://1password.com/ko/"
  }, "가족, 기업, 팀을 위한 비밀번호 관리 솔루션 | 1Password")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.mozilla.org/en-US/firefox/lockwise/"
  }, "Firefox Lockwise — password manager — take your passwords everywhere")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
